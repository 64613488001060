import { Setup, SetupsResponse } from 'src/queries/setups/getSetups';
import { StateCreator, StoreApi } from 'zustand';

export type SetupFilters = {
    [filterKey: string]: string[];
};

export interface SetupsValuesSlice {
    originalSetupsResponse: SetupsResponse | undefined;
    filters: SetupFilters;
    allSetups: Setup[];
    setupNextPage: number;
}

export interface SetupsActionsSlice {
    setOriginalSetupsResponse: (originalSetupsResponse: SetupsResponse | undefined) => void;
    setFilters: (filters: SetupFilters) => void;
    addFilter: (filterKey: string, filterId: string) => void;
    resetFilters: () => void;
    addSetups: (setups: Setup[]) => void;
    resetSetups: () => void;
}

export interface SetupsSlice {
    setups: SetupsValuesSlice & SetupsActionsSlice;
}

export const setupsInitialValuesState: SetupsValuesSlice = {
    originalSetupsResponse: undefined,
    filters: {},
    allSetups: [],
    setupNextPage: 1
};

const setSlice = (
    param: (state: SetupsSlice['setups']) => Partial<SetupsSlice['setups']>,
    set: (
        partial: SetupsSlice | Partial<SetupsSlice> | ((state: SetupsSlice) => SetupsSlice | Partial<SetupsSlice>)
    ) => void
) =>
    set((state) => ({
        setups: { ...state.setups, ...param(state.setups) }
    }));

export const createSetupsSlice: StateCreator<SetupsSlice> = (set) => ({
    setups: {
        ...setupsInitialValuesState,
        setOriginalSetupsResponse: (originalSetupsResponse: SetupsResponse | undefined) =>
            setSlice(() => ({ originalSetupsResponse }), set),
        setFilters: (filters: SetupFilters) => setSlice(() => ({ filters }), set),
        addFilter: (filterKey: string, filterId: string) =>
            setSlice((state) => {
                const filters = { ...state.filters };

                if (filters[filterKey]) {
                    if (filters[filterKey].find((filter) => filter === filterId)) {
                        const filterIndex = filters[filterKey].findIndex((filter) => filter === filterId);

                        if (filters[filterKey].length === 1) {
                            delete filters[filterKey];
                        } else {
                            filters[filterKey] = [
                                ...filters[filterKey].slice(0, filterIndex),
                                ...filters[filterKey].slice(filterIndex + 1)
                            ];
                        }
                    } else {
                        filters[filterKey].push(filterId);
                    }
                } else {
                    filters[filterKey] = [filterId];
                }

                return { filters };
            }, set),
        resetFilters: () => setSlice(() => ({ filters: {} }), set),
        addSetups: (setups: Setup[]) =>
            setSlice((state) => {
                if (!setups.length) {
                    return { allSetups: state.allSetups };
                }

                const uniqueSetups = Object.values(
                    Object.fromEntries([...state.allSetups, ...setups].map((setup) => [setup.id, setup]))
                );

                return { allSetups: uniqueSetups, setupNextPage: state.setupNextPage + 1 };
            }, set),
        resetSetups: () => setSlice(() => ({ allSetups: [], setupNextPage: 1 }), set)
    }
});

export default createSetupsSlice as (
    set: StoreApi<SetupsSlice>['setState'],
    get: StoreApi<SetupsSlice>['getState'],
    api: StoreApi<SetupsSlice>
) => SetupsSlice;

import { NewsOnboardingModalStorage } from 'components/News/NewsOnboardingModal';
import { SupportedLanguages } from 'src/i18n';
import { StateCreator, StoreApi } from 'zustand';

export interface StorageValuesSlice {
    savedRoute: string;
    identitiesRoute: string;
    completedCountdown: boolean;
    slide: number;
    hasRegistered: boolean;
    firstSessionAnalysed: boolean;
    showReferralBanner: boolean;
    showSetupTooltipCars: string[];
    ghostEventDownloaded: boolean;
    signupTime: number;
    kphOrMph: 'kph' | 'mph';
    registerId: string;
    hasCameFromSetups: boolean;
    followTimestamp: number;
    hasSeenIntercomSurveyQuickTips: boolean;
    hasSentIntercomLoadedEvent: boolean;
    racingLineThickness: number;
    hasSeenDrivingAssessment: boolean;
    hasClickedPremiumPlanLink: boolean;
    numberOfTimesViewedDisordButton: number;
    hasClickedDiscordButton: boolean;
    unauthEvents: string[];
    language: SupportedLanguages;
    sanityInfoModalsOpened: Record<string, boolean>;
    hasUpdatedUserType: boolean;
    newsEngineerPersonalisation: NewsOnboardingModalStorage;
    useBrakingPoints: boolean;
    activationSelectedGame: string | undefined;
    activationConfiguredGame: boolean;
    useMapRotation: boolean;
}

export interface StorageActionsSlice {
    setSavedRoute: (savedRoute: string) => void;
    setIdentitiesRoute: (identitiesRoute: string) => void;
    setCompletedCountdown: (completedCountdown: boolean) => void;
    setSlide: (slide: number) => void;
    setHasRegistered: (hasRegistered: boolean) => void;
    setFirstSessionAnalysed: (firstSessionAnalysed: boolean) => void;
    setShowReferralBanner: (showReferralBanner: boolean) => void;
    addShowSetupTooltipCar: (showSetupTooltipCar: string) => void;
    setGhostEventDownloaded: (ghostEventDownloaded: boolean) => void;
    setSignupTime: (signupTime: number) => void;
    setKphOrMph: (kphOrMph: 'kph' | 'mph') => void;
    setRegisterId: (registerId: string) => void;
    setHasCameFromSetups: (hasCameFromSetups: boolean) => void;
    setFollowTimestamp: (followTimestamp: number) => void;
    setHasSeenIntercomSurveyQuickTips: (hasSeenIntercomSurveyQuickTips: boolean) => void;
    setHasSentIntercomLoadedEvent: (hasSentIntercomLoadedEvent: boolean) => void;
    setRacingLineThickness: (racingLineThickness: number) => void;
    setHasSeenDrivingAssessment: (hasSeenDrivingAssessment: boolean) => void;
    setHasClickedPremiumPlanLink: (hasClickedPremiumPlanLink: boolean) => void;
    increaseNumberOfTimesViewedDisordButton: () => void;
    setHasClickedDiscordButton: (hasClickedDiscordButton: boolean) => void;
    addUnauthEvent: (unauthEvent: string) => void;
    resetUnauthEvents: () => void;
    setLanguage: (language: SupportedLanguages) => void;
    addSanityInfoModalOpened: (modalId: string) => void;
    setHasUpdatedUserType: (hasUpdatedUserType: boolean) => void;
    setNewsEngineerPersonalisation: (newsEngineerPersonalisation: NewsOnboardingModalStorage) => void;
    setUseBrakingPoints: (useBrakingPoints: boolean) => void;
    setActivationSelectedGame: (activationSelectedGame?: string) => void;
    setActivationConfiguredGame: (activationConfiguredGame: boolean) => void;
    setUseMapRotation: (useMapRotation: boolean) => void;
}

export interface StorageSlice {
    storage: StorageValuesSlice & StorageActionsSlice;
}

export const storageInitialValuesState: StorageValuesSlice = {
    savedRoute: '',
    identitiesRoute: '',
    completedCountdown: false,
    slide: 1,
    hasRegistered: false,
    firstSessionAnalysed: false,
    showReferralBanner: true,
    showSetupTooltipCars: [],
    ghostEventDownloaded: false,
    signupTime: 0,
    kphOrMph: 'kph',
    registerId: '',
    hasCameFromSetups: false,
    followTimestamp: Date.now(),
    hasSeenIntercomSurveyQuickTips: false,
    hasSentIntercomLoadedEvent: false,
    racingLineThickness: 2,
    hasSeenDrivingAssessment: true,
    hasClickedPremiumPlanLink: false,
    numberOfTimesViewedDisordButton: 0,
    hasClickedDiscordButton: false,
    unauthEvents: [],
    language: 'en',
    sanityInfoModalsOpened: {},
    hasUpdatedUserType: false,
    newsEngineerPersonalisation: { dismissed: {}, signup: { language: '', interests: [] } },
    useBrakingPoints: true,
    activationSelectedGame: undefined,
    activationConfiguredGame: false,
    useMapRotation: false
};

const setSlice = (
    param: (state: StorageSlice['storage']) => Partial<StorageSlice['storage']>,
    set: (
        partial: StorageSlice | Partial<StorageSlice> | ((state: StorageSlice) => StorageSlice | Partial<StorageSlice>)
    ) => void
) =>
    set((state) => ({
        storage: { ...state.storage, ...param(state.storage) }
    }));

export const createStorageSlice: StateCreator<StorageSlice> = (set) => ({
    storage: {
        ...storageInitialValuesState,
        setSavedRoute: (savedRoute: string) => setSlice(() => ({ savedRoute }), set),
        setIdentitiesRoute: (identitiesRoute: string) => setSlice(() => ({ identitiesRoute }), set),
        setCompletedCountdown: (completedCountdown: boolean) => setSlice(() => ({ completedCountdown }), set),
        setSlide: (slide: number) => setSlice(() => ({ slide }), set),
        setHasRegistered: (hasRegistered: boolean) => setSlice(() => ({ hasRegistered }), set),
        setFirstSessionAnalysed: (firstSessionAnalysed: boolean) => setSlice(() => ({ firstSessionAnalysed }), set),
        setShowReferralBanner: (showReferralBanner: boolean) => setSlice(() => ({ showReferralBanner }), set),
        addShowSetupTooltipCar: (showSetupTooltipCar: string) =>
            setSlice(
                (state) => ({ ...state, showSetupTooltipCars: [...state.showSetupTooltipCars, showSetupTooltipCar] }),
                set
            ),
        setGhostEventDownloaded: (ghostEventDownloaded: boolean) => setSlice(() => ({ ghostEventDownloaded }), set),
        setSignupTime: (signupTime: number) => setSlice(() => ({ signupTime }), set),
        setKphOrMph: (kphOrMph: 'kph' | 'mph') => setSlice(() => ({ kphOrMph }), set),
        setRegisterId: (registerId: string) => setSlice(() => ({ registerId }), set),
        setHasCameFromSetups: (hasCameFromSetups: boolean) => setSlice(() => ({ hasCameFromSetups }), set),
        setFollowTimestamp: (followTimestamp: number) => setSlice(() => ({ followTimestamp }), set),
        setHasSeenIntercomSurveyQuickTips: (hasSeenIntercomSurveyQuickTips: boolean) =>
            setSlice(() => ({ hasSeenIntercomSurveyQuickTips }), set),
        setHasSentIntercomLoadedEvent: (hasSentIntercomLoadedEvent: boolean) =>
            setSlice(() => ({ hasSentIntercomLoadedEvent }), set),
        setRacingLineThickness: (racingLineThickness: number) => setSlice(() => ({ racingLineThickness }), set),
        setHasClickedPremiumPlanLink: (hasClickedPremiumPlanLink: boolean) =>
            setSlice(() => ({ hasClickedPremiumPlanLink }), set),
        increaseNumberOfTimesViewedDisordButton: () =>
            setSlice(
                ({ numberOfTimesViewedDisordButton }) => ({
                    numberOfTimesViewedDisordButton: numberOfTimesViewedDisordButton + 1
                }),
                set
            ),
        setHasClickedDiscordButton: (hasClickedDiscordButton: boolean) =>
            setSlice(() => ({ hasClickedDiscordButton }), set),
        addUnauthEvent: (unauthEvent: string) =>
            setSlice((state) => ({ ...state, unauthEvents: [...state.unauthEvents, unauthEvent] }), set),
        resetUnauthEvents: () => setSlice(() => ({ unauthEvents: [] }), set),
        setHasSeenDrivingAssessment: (hasSeenDrivingAssessment: boolean) =>
            setSlice(() => ({ hasSeenDrivingAssessment }), set),
        setLanguage: (language: SupportedLanguages) => setSlice(() => ({ language }), set),
        addSanityInfoModalOpened: (modalId: string) =>
            setSlice(
                (state) => ({ ...state, sanityInfoModalsOpened: { ...state.sanityInfoModalsOpened, [modalId]: true } }),
                set
            ),
        setHasUpdatedUserType: (hasUpdatedUserType) => setSlice(() => ({ hasUpdatedUserType }), set),
        setNewsEngineerPersonalisation: (newsEngineerPersonalisation) =>
            setSlice(() => ({ newsEngineerPersonalisation }), set),
        setUseBrakingPoints: (useBrakingPoints: boolean) => setSlice(() => ({ useBrakingPoints }), set),
        setActivationSelectedGame: (activationSelectedGame?: string) =>
            setSlice(() => ({ activationSelectedGame }), set),
        setActivationConfiguredGame: (activationConfiguredGame: boolean) =>
            setSlice(() => ({ activationConfiguredGame }), set),
        setUseMapRotation: (useMapRotation: boolean) => setSlice(() => ({ useMapRotation }), set)
    }
});

export default createStorageSlice as (
    set: StoreApi<StorageSlice>['setState'],
    get: StoreApi<StorageSlice>['getState'],
    api: StoreApi<StorageSlice>
) => StorageSlice;
